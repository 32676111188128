<template>
<v-lazy
        v-model="isActive"
        :options="{
          threshold: .1
        }"
        min-height="200"
        transition="fade-transition"
      >
	<v-card :loading="loading" class="mx-auto" :disabled="disabledWish || loading">
		<div>
			<ConfirmDialog @yes="takeWish(false)" title="Wunsch nicht mehr erfüllen" message="Möchten Sie diesen Wunsch wirklich nicht mehr erfüllen? Bitte beachten Sie, dass andere Mitarbeiter zum Erfüllen des Wunsches ebenfalls Zeit brauchen." :active="dialogs.untakeWish"></ConfirmDialog>
			<ConfirmDialog @yes="deleteWish" title="Wunsch löschen" message="Möchten Sie diesen Wunsch wirklich löschen? Dies kann nicht rückgängig gemacht werden!" :active="dialogs.deleteWish"></ConfirmDialog>
			<AlertDialog :title="alert.title" :message="alert.message" :active="alert.active"></AlertDialog>
		</div>
		<v-toolbar v-if="showActions" flat dense>
			<v-btn @click="dialogs.deleteWish++" icon>
				<v-icon>mdi-delete</v-icon>
			</v-btn>
    	</v-toolbar>
		<v-toolbar v-if="takenWish" flat dense>
			<v-spacer></v-spacer>
			<v-menu bottom left offset-y v-if="!wish.completed">
				<template v-slot:activator="{ on, attrs }">
					<v-btn icon v-bind="attrs" v-on="on">
						<v-icon>mdi-dots-vertical</v-icon>
					</v-btn>
				</template>
				<v-list>
					<v-list-item link @click="dialogs.untakeWish++">
						<v-list-item-title>Diesen Wunsch nicht mehr erfüllen</v-list-item-title>
					</v-list-item>
				</v-list>
			</v-menu>
    	</v-toolbar>
		<v-hover>
			<template v-slot:default="{ hover }">
				<v-img :src="imageUrl" :aspect-ratio="11/9">
					<template v-slot:placeholder>
						<v-row class="fill-height ma-0" align="center" justify="center">
							<v-progress-circular indeterminate color="primary"></v-progress-circular>
						</v-row>
					</template>
					<v-fade-transition>
						<v-overlay v-if="hover" absolute color="primary">
							<v-btn @click="fullscreenPicture" large><v-icon left>mdi-fullscreen</v-icon>Vollbildansicht</v-btn>
						</v-overlay>
					</v-fade-transition>
				</v-img>
			</template>
		</v-hover>
		<v-card-title>{{wish.title}}</v-card-title>
		<v-card-text>
			<div class="subtitle-1">Von: {{wish.name}}</div>
			<div class="subtitle-1">Alter: {{wish.age == 0 ? 'Alter unbekannt' : wish.age + " Jahre"}}</div>
			<!--<div>
				Small plates, salads & sandwiches - an intimate setting with 12
				indoor seats plus patio seating.
			</div>-->
		</v-card-text>
		<v-divider class="mx-4"></v-divider>
		<v-card-actions>
			<v-btn v-if="!takenWish && !disabledWish" color="primaryLight" text @click="takeWish(true)">Wunsch erfüllen</v-btn>
			<v-btn v-else-if="iTookTheWish && !takenWish" color="red" text>An Sie vergeben</v-btn>
			<v-btn v-else-if="!takenWish" color="red" text>Bereits vergeben</v-btn>
			<v-btn v-else @click="completeWish(!wish.completed)" text color="primaryLight">{{!wish.completed ? 'Erledigt' : 'Nicht mehr erledigt'}}</v-btn>
			<v-spacer></v-spacer>
			<v-tooltip top v-if="shareable">
				<template v-slot:activator="{ on, attrs }">
					<v-btn @click="share" target="_blank" icon v-bind="attrs" v-on="on"><v-icon>mdi-share-variant</v-icon></v-btn>
				</template>
				<span>Link zum Teilen aufrufen</span>
			</v-tooltip>
			<v-tooltip top v-if="wish.link">
				<template v-slot:activator="{ on, attrs }">
					<v-btn :href="wish.link" target="_blank" icon v-bind="attrs" v-on="on"><v-icon>mdi-web</v-icon></v-btn>
				</template>
				<span>{{wish.link}}</span>
			</v-tooltip>
			<v-tooltip top v-else>
				<template v-slot:activator="{ on, attrs }">
					<v-btn :href="'https://www.amazon.de/s?k=' + encodeURIComponent(wish.title)" target="_blank" icon v-bind="attrs" v-on="on"><v-icon>mdi-magnify</v-icon></v-btn>
				</template>
				<span>Amazon nach <b>{{wish.title}}</b> durchsuchen</span>
			</v-tooltip>
		</v-card-actions>
	</v-card>
</v-lazy>
</template>
<script>
import { deleteObject, getDownloadURL, ref } from '@firebase/storage';
import { firestore, storage } from '../../firebase';
import { collection, deleteDoc, doc, updateDoc } from '@firebase/firestore';

export default {
	data: () => ({
		iTookTheWish: false,
		isActive: false,
		dialogs: {
			deleteWish: 0,
			untakeWish: 0
		},
		alert: {
			title: "",
			message: "",
			active: 0
		},
		loading: false,
		selection: 1,
		imageUrl: ""
	}),
	props: {
		wish: {
			type: Object,
			default: null
		},
		edit: {
			type: Boolean,
			default: false
		},
		shareable: {
			type: Boolean,
			default: false
		},
		sharedWish: {
			type: Boolean,
			default: false
		}
	},
	mounted()  {
		getDownloadURL(ref(storage, 'wishes/' + this.wish._id + ".jpg")).then((url) => {
            this.imageUrl = url;
		}).catch((error) => {
			console.info(error)
		});
	},
	computed: {
		shareLink() {
			return "mailto:?subject=test"
		},
		takenWish() {
			if(this.wish.uid == null) {
				return false;
			}
			return this.wish.uid == this.$store.state.auth.profile.sub;
		},
		showActions() {
			return !this.takenWish && this.$store.state.adminMode && !this.edit
		},
		disabledWish() {
			return !this.sharedWish && !this.takenWish && this.$store.state.wishesLive[this.wish._id] !== true;
		}
	},
	methods: {
		share() {
			this.$emit("share", "https://wunschbaum.apps.new.de/wishes?share=" + this.wish._id);
		},
		deleteWish() {
			this.loading = true;
			deleteObject(ref(storage, 'wishes/' + this.wish._id + ".jpg")).then(() => {
				deleteDoc(doc(collection(firestore, "wishes"), this.wish._id)).then(() => {
					this.loading = false;
				});
			}).catch(error => {
				this.loading = false;
			});
		},
		fullscreenPicture() {
			this.$store.state.fullscreenImage = this.imageUrl;
		},
		completeWish(state) {
			this.loading = true;
			updateDoc(doc(collection(firestore, "wishes"), this.wish._id), {
				completed: state
			}).then(() => {
				this.$emit("completeWish");
				this.loading = false
			}).catch(error => {
				this.loading = false
				console.error(error);
				this.alert.title = "Unbekannter Fehler";
				this.alert.message = "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.";
				this.alert.active++;
			})
		},
		takeWish(state) {
			this.loading = true;
			updateDoc(doc(collection(firestore, "wishes"), this.wish._id), {
				uid: state ? loginNew.getProfile().sub : null
			}).then(() => {
				this.$emit("takeWish");
				this.loading = false
				this.alert.title = "Wunsch erfüllt!";
				this.alert.message = "Sie finden diesen Wunsch nun unter 'Meine Erfüllungen'";
				this.alert.active++;
				this.iTookTheWish = true;
			}).catch(error => {
				this.loading = false
				if(state) {
					this.alert.title = "Wunsch erfüllen";
					this.alert.message = "Dieser Wunsch konnte Ihnen leider nicht zugeweisen werden.";
				}else{
					this.alert.title = "Unbekannter Fehler";
					this.alert.message = "Es ist ein unbekannter Fehler aufgetreten. Bitte versuchen Sie es später erneut.";
				}
				this.alert.active++;
			})
		},
	},
};
</script>