var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-lazy',{attrs:{"options":{
          threshold: .1
        },"min-height":"200","transition":"fade-transition"},model:{value:(_vm.isActive),callback:function ($$v) {_vm.isActive=$$v},expression:"isActive"}},[_c('v-card',{staticClass:"mx-auto",attrs:{"loading":_vm.loading,"disabled":_vm.disabledWish || _vm.loading}},[_c('div',[_c('ConfirmDialog',{attrs:{"title":"Wunsch nicht mehr erfüllen","message":"Möchten Sie diesen Wunsch wirklich nicht mehr erfüllen? Bitte beachten Sie, dass andere Mitarbeiter zum Erfüllen des Wunsches ebenfalls Zeit brauchen.","active":_vm.dialogs.untakeWish},on:{"yes":function($event){return _vm.takeWish(false)}}}),_c('ConfirmDialog',{attrs:{"title":"Wunsch löschen","message":"Möchten Sie diesen Wunsch wirklich löschen? Dies kann nicht rückgängig gemacht werden!","active":_vm.dialogs.deleteWish},on:{"yes":_vm.deleteWish}}),_c('AlertDialog',{attrs:{"title":_vm.alert.title,"message":_vm.alert.message,"active":_vm.alert.active}})],1),(_vm.showActions)?_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.dialogs.deleteWish++}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1):_vm._e(),(_vm.takenWish)?_c('v-toolbar',{attrs:{"flat":"","dense":""}},[_c('v-spacer'),(!_vm.wish.completed)?_c('v-menu',{attrs:{"bottom":"","left":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){_vm.dialogs.untakeWish++}}},[_c('v-list-item-title',[_vm._v("Diesen Wunsch nicht mehr erfüllen")])],1)],1)],1):_vm._e()],1):_vm._e(),_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
        var hover = ref.hover;
return [_c('v-img',{attrs:{"src":_vm.imageUrl,"aspect-ratio":11/9},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":"primary"}})],1)]},proxy:true}],null,true)},[_c('v-fade-transition',[(hover)?_c('v-overlay',{attrs:{"absolute":"","color":"primary"}},[_c('v-btn',{attrs:{"large":""},on:{"click":_vm.fullscreenPicture}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-fullscreen")]),_vm._v("Vollbildansicht")],1)],1):_vm._e()],1)],1)]}}])}),_c('v-card-title',[_vm._v(_vm._s(_vm.wish.title))]),_c('v-card-text',[_c('div',{staticClass:"subtitle-1"},[_vm._v("Von: "+_vm._s(_vm.wish.name))]),_c('div',{staticClass:"subtitle-1"},[_vm._v("Alter: "+_vm._s(_vm.wish.age == 0 ? 'Alter unbekannt' : _vm.wish.age + " Jahre"))])]),_c('v-divider',{staticClass:"mx-4"}),_c('v-card-actions',[(!_vm.takenWish && !_vm.disabledWish)?_c('v-btn',{attrs:{"color":"primaryLight","text":""},on:{"click":function($event){return _vm.takeWish(true)}}},[_vm._v("Wunsch erfüllen")]):(_vm.iTookTheWish && !_vm.takenWish)?_c('v-btn',{attrs:{"color":"red","text":""}},[_vm._v("An Sie vergeben")]):(!_vm.takenWish)?_c('v-btn',{attrs:{"color":"red","text":""}},[_vm._v("Bereits vergeben")]):_c('v-btn',{attrs:{"text":"","color":"primaryLight"},on:{"click":function($event){return _vm.completeWish(!_vm.wish.completed)}}},[_vm._v(_vm._s(!_vm.wish.completed ? 'Erledigt' : 'Nicht mehr erledigt'))]),_c('v-spacer'),(_vm.shareable)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"target":"_blank","icon":""},on:{"click":_vm.share}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-share-variant")])],1)]}}],null,false,1807302408)},[_c('span',[_vm._v("Link zum Teilen aufrufen")])]):_vm._e(),(_vm.wish.link)?_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":_vm.wish.link,"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-web")])],1)]}}],null,false,1099864867)},[_c('span',[_vm._v(_vm._s(_vm.wish.link))])]):_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"href":'https://www.amazon.de/s?k=' + encodeURIComponent(_vm.wish.title),"target":"_blank","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-magnify")])],1)]}}])},[_c('span',[_vm._v("Amazon nach "),_c('b',[_vm._v(_vm._s(_vm.wish.title))]),_vm._v(" durchsuchen")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }